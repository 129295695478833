import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDropzone } from 'react-dropzone';
import { FaUndo, FaRedo, FaTrash, FaImage, FaTimes } from 'react-icons/fa';

const ImageEditor = ({ id, onCropComplete, updateEditorData, onImageUploaded, isFirstEditor, onRemove, totalEditors }) => {

    const [imgSrc, setImgSrc] = useState('');
    const [crop, setCrop] = useState({ unit: '%', width: 50, height: 50, x: 25, y: 25, aspect: 16 / 9 });
    const [completedCrop, setCompletedCrop] = useState();
    const [rotation, setRotation] = useState(0);
    const [generatedImage, setGeneratedImage] = useState(null);
    const [maxDimensions, setMaxDimensions] = useState({ width: 0, height: 0 });

    const imgRef = useRef(null);

    useEffect(() => {
        if (completedCrop) {
            onCropComplete(id, true);
        } else {
            onCropComplete(id, false);
        }
    }, [completedCrop, id, onCropComplete]);


    useEffect(() => {
        const updateMaxDimensions = () => {
            setMaxDimensions({
                width: window.innerWidth * 0.75,
                height: window.innerHeight * 0.75
            });
        };

        updateMaxDimensions();
        window.addEventListener('resize', updateMaxDimensions);

        return () => window.removeEventListener('resize', updateMaxDimensions);
    }, []);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setImgSrc(reader.result?.toString() || '');
            onImageUploaded(); // Call this new prop function when an image is uploaded
        });
        reader.readAsDataURL(file);
        const box = document.getElementById(`box-${id}`);
        if (box) {
            box.style.display = 'none';
        }
    }, [id, onImageUploaded]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { 'image/*': [] } });

    const handleRotation = (angle) => {
        setRotation((prevRotation) => (prevRotation + angle) % 360);
    };

    const prepareSubmitData = async () => {
        if (!completedCrop || !imgRef.current) return;

        const croppedImageBlob = await getCroppedImg(imgRef.current, completedCrop, 'croppedImage.jpeg', rotation);

        updateEditorData(id, {
            croppedImage: croppedImageBlob
        });
    };

    useEffect(() => {
        prepareSubmitData();
    }, [completedCrop, rotation]);


    const handleReset = () => {
        setImgSrc('');
        setCrop(undefined);
        setCompletedCrop(undefined);

        setRotation(0);
        setGeneratedImage(null);

        document.getElementById('box1').style.display = 'block';
    };

    const onImageLoaded = useCallback((image) => {
        const aspect = 16 / 9;
        const width = 30;
        const height = width / aspect;
        const y = (100 - height) / 2;
        const x = (100 - width) / 2;
        setCrop({ unit: '%', width, height, x, y });
    }, []);

    function getCroppedImg(image, crop, fileName, rotation = 0) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const rotRad = getRadianAngle(rotation);

        // Calculate bounding box of the rotated image
        const bBoxWidth = rotateSize(crop.width * scaleX, crop.height * scaleY, rotation).width;
        const bBoxHeight = rotateSize(crop.width * scaleX, crop.height * scaleY, rotation).height;

        // Set canvas size to match the bounding box
        canvas.width = bBoxWidth;
        canvas.height = bBoxHeight;

        // Translate canvas context to a central location to allow rotating around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
        ctx.rotate(rotRad);
        ctx.translate(-crop.width * scaleX / 2, -crop.height * scaleY / 2);

        // Draw rotated image
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        // As a blob
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                if (blob) {
                    blob.name = fileName;
                    resolve(blob);
                }
            }, 'image/jpeg', 1);
        });
    }

    function getRadianAngle(degreeValue) {
        return (degreeValue * Math.PI) / 180;
    }

    function rotateSize(width, height, rotation) {
        const rotRad = getRadianAngle(rotation);

        return {
            width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        };
    }
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);


    return (
        <div className='container rounded mt-4'>
            <div className="row justify-content-center">
                <div className="col-12 d-flex justify-content-center" style={{ padding: '10px' }}>
                    <div
                        id={`box-${id}`}
                        style={{ display: imgSrc ? 'none' : 'block', position: 'relative' }}
                        className="col-10 mt-2"
                    >
                        {totalEditors > 1 && (
                            <button
                                className="btn btn-sm  position-absolute text-secondary"
                                style={{ top: '5px', right: '5px', zIndex: 1000 }}
                                onClick={() => onRemove(id)}
                            >
                                <FaTimes />
                            </button>
                        )}

                        <div {...getRootProps()} className="shadow" style={{
                            background: 'linear-gradient(to bottom, rgba(252, 163, 17, 0.1), rgba(255, 255, 255, 0.1))',
                            borderRadius: '20px',
                            border: '2px dashed #fca311',
                            padding: '10px',
                            textAlign: 'center',
                            cursor: 'pointer'
                        }}>

                            <div>
                                <input {...getInputProps()} />
                                <FaImage className="my-3" style={{ width: '50px', height: '43px', color: '#92b0b3' }} />

                                {isMobile ? (
                                    <p className="text-black" style={{ fontSize: '1.25rem' }}>
                                        <strong>Toca aquí</strong> para seleccionar una fotografía del DNI
                                    </p>
                                ) : (
                                    <p className="text-black" style={{ fontSize: '1.25rem' }}><strong>Arrastra o selecciona </strong> una fotografía del DNI</p>
                                )}
                            </div>
                        </div>
                    </div>

                    {imgSrc && (
                        <>
                            <div className="col-12 shadow-sm p-2" >
                                <ReactCrop
                                    crop={crop}
                                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    onImageLoaded={onImageLoaded}
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrc}
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: `${maxDimensions.height}px`,
                                            width: 'auto',
                                            height: 'auto',
                                            transform: `rotate(${rotation}deg)`
                                        }}
                                    />
                                </ReactCrop>
                                <div className="justify-content-center">
                                    <button className="btn btn-light text-dark mx-1" onClick={() => handleRotation(-90)}><FaUndo /></button>
                                    <button className="btn btn-light text-dark mx-1" onClick={() => handleRotation(90)}><FaRedo /></button>
                                    <button className="btn btn-light text-dark mx-1" onClick={() => handleReset()}><FaTrash /></button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImageEditor;
