import React from 'react';
import { FaCloudUploadAlt, FaCrop, FaFileExport } from 'react-icons/fa';
import Typewriter from 'typewriter-effect';
import SliderCard from './SliderCard';
import InfoCards from './InfoCards';

function Instrucciones() {
  return (
    <>
      <div className="text-center mb-4 mt-4" >
        <h1 className="display-4 fw-bold">
          Protege tu DNI {' '}
          <span style={{ color: '#FF9800' }}>
            <Typewriter
              options={{
                strings: ['100% seguro', 'fácil', 'en 3 pasos'],
                autoStart: true,
                loop: true,
                speed: 20,
                deleteSpeed: 100,
                delay: 100,
                pauseFor: 500,
              }}
            />
          </span>
        </h1>
        <p className="lead" style={{ fontSize: '1.5rem', color: '#222831' }}>Retoca, guarda y comparte tu DNI de manera segura</p>
      </div>

      <div className='row justify-content-center'>
        <div className='col-md-8 col-10'>
          <InfoCards />
        </div>
      </div>
    </>
  );
}

export default Instrucciones;