import React from 'react';
import { FaMagic, FaShare } from 'react-icons/fa';
import { FaUpload } from 'react-icons/fa6';

const InfoCards = () => {
  const cards = [
    { icon: FaUpload, title: "Sube", text: "Selecciona una foto de tu DNI" },
    { icon: FaMagic, title: "Edita", text: "Recorta, pixela, convierte a b&n o añade una marca de agua" },
    { icon: FaShare, title: "Listo", text: "Guárdalo como PDF o JPG" },
  ];

  return (
    <div className="row my-2" style={{ color: '#14213d' }}>
      {cards.map((card, index) => (
        <div key={index} className=" col-4 px-1 mb-0">
          <div className=" mx-lg-2">
            <div className="px-lg-4 px-sm-2 py-2">
              <div className="text-center mb-4">
                <card.icon size={40} color="#393E46" />
              </div>
              <h5 className="text-start">
                <strong>{index + 1}. {card.title}</strong>
              </h5>
              <p className="text-start">{card.text}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InfoCards;