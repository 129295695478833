import React from 'react';
import { FaFilePdf, FaImage, FaShare } from 'react-icons/fa';
import { trackEvent } from './common';

function Result({ processedImages, exportToPDF, exportAsCombinedImage }) {
  const shareImage = async () => {
    if (processedImages.length > 0) {
      try {
        trackEvent('share', 'Compartir');
        const response = await fetch(processedImages[0]);
        const blob = await response.blob();
        const file = new File([blob], 'dni_seguro.jpg', { type: 'image/jpeg' });

        if (navigator.share) {
          await navigator.share({
            title: 'DNI Seguro',
            text: 'Mira esta imagen procesada con dniseguro.es',
            files: [file],
          });
        } else {
          console.log('Web Share API not supported');
        }
      } catch (error) {
        console.error('Error sharing image:', error);
      }
    }
  };

  return (
    <div className="w-100 py-4 text-white" style={{ backgroundColor: '#393E46' }}>
      <h2 className="mb-4">Comparte tu DNI seguro</h2>
      <div className="d-flex flex-wrap justify-content-center">
        {processedImages.map((image, index) => (
          <div key={index} className="m-2">
            <img src={image} alt={`Processed Image ${index + 1}`} style={{ maxWidth: '300px', height: 'auto' }} />
          </div>
        ))}
      </div>
      <div className='container mt-4'>
        <button className="btn mx-1 mb-2" style={{ backgroundColor: '#222831', color: 'white' }} onClick={exportToPDF}>
          <FaFilePdf className='mx-1' style={{ color: '#fca311' }} />
          Guardar PDF
        </button>
        <button className="btn mx-1 mb-2" style={{ backgroundColor: '#222831', color: 'white' }} onClick={exportAsCombinedImage}>
          <FaImage className='mx-1' style={{ color: '#fca311' }} />
          Guardar imagen
        </button>
        {window.navigator.userAgent.match(/Android|iPhone|iPad|iPod/i) && (
          <button
            className="btn mx-1 mb-2"
            style={{ backgroundColor: '#222831', color: 'white' }}
            onClick={shareImage}
          >
            <FaShare className='mx-1' style={{ color: '#fca311' }} />
            Compartir
          </button>
        )}
      </div>
    </div>
  );
}

export default Result;