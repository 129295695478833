import * as faceapi from 'face-api.js';

export const pixelateFaces = async (canvas) => {
  const detections = await faceapi.detectAllFaces(canvas);
  const ctx = canvas.getContext('2d');

  detections.forEach(detection => {
    const { x, y, width, height } = detection.box;
    const pixelSize = Math.max(width, height) / 15;

    for (let i = 0; i < width; i += pixelSize) {
      for (let j = 0; j < height; j += pixelSize) {
        const pixelX = x + i;
        const pixelY = y + j;
        const pixelColor = ctx.getImageData(pixelX, pixelY, 1, 1).data;
        ctx.fillStyle = `rgba(${pixelColor[0]}, ${pixelColor[1]}, ${pixelColor[2]}, ${pixelColor[3]})`;
        ctx.fillRect(pixelX, pixelY, pixelSize, pixelSize);
      }
    }
  });
};

export const convertToBlackAndWhite = (canvas) => {
  const ctx = canvas.getContext('2d');
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  for (let i = 0; i < data.length; i += 4) {
    const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
    data[i] = data[i + 1] = data[i + 2] = avg;
  }

  ctx.putImageData(imageData, 0, 0);
};

export const addWatermark = (canvas, text) => {
  const ctx = canvas.getContext('2d');
  const diagonal = Math.sqrt(canvas.width * canvas.width + canvas.height * canvas.height);
  ctx.save();
  ctx.globalAlpha = 0.5;
  ctx.fillStyle = 'white';
  ctx.font = `${diagonal / text.length}px Arial`;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate(Math.atan2(canvas.height, canvas.width));
  ctx.fillText(text, 0, 0);
  ctx.restore();
};