import React from 'react';
import { FaIdCard } from 'react-icons/fa';

const Header = () => {
  return (
    <header className="bg-white ps-3 py-2 mb-2">
      <div className="text-start">
        <h1
          className="mb-0 text-start"
          style={{ fontSize: '25px', cursor: 'pointer', color: '#393E46' }}
          onClick={() => window.location.reload()}
        >
          <FaIdCard /> dni<span style={{ color: '#FF9800' }}>seguro</span>.es
        </h1>
      </div>
    </header>
  );
};

export default Header;