import jsPDF from 'jspdf';

export const exportToPDF = (processedImages) => {
  const pdf = new jsPDF();

  processedImages.forEach((image, index) => {
    if (index > 0) {
      pdf.addPage();
    }

    const imgProps = pdf.getImageProperties(image);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = imgProps.width;
    const imgHeight = imgProps.height;

    let finalWidth, finalHeight;
    if (imgWidth > imgHeight) {
      finalWidth = pdfWidth;
      finalHeight = (imgHeight * pdfWidth) / imgWidth;
    } else {
      finalHeight = pdfHeight;
      finalWidth = (imgWidth * pdfHeight) / imgHeight;
    }

    const x = (pdfWidth - finalWidth) / 2;
    const y = (pdfHeight - finalHeight) / 2;

    pdf.addImage(image, 'JPEG', x, y, finalWidth, finalHeight);
  });

  pdf.save('dni_seguro.pdf');
};

export const exportAsCombinedImage = (processedImages) => {
  if (processedImages.length === 0) return;

  if (processedImages.length === 1) {
    const link = document.createElement('a');
    link.href = processedImages[0];
    link.download = 'dni_seguro.jpg';
    link.click();
  } else {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    let totalHeight = 0;
    let maxWidth = 0;

    const imgPromises = processedImages.map(src => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          totalHeight += img.height;
          maxWidth = Math.max(maxWidth, img.width);
          resolve(img);
        };
        img.onerror = reject;
        img.src = src;
      });
    });

    Promise.all(imgPromises).then(images => {
      canvas.width = maxWidth;
      canvas.height = totalHeight;

      let y = 0;
      images.forEach(img => {
        ctx.drawImage(img, 0, y);
        y += img.height;
      });

      canvas.toBlob(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'combined_processed_images.jpg';
        link.click();
        URL.revokeObjectURL(url);
      }, 'image/jpeg');
    });
  }
};

export const trackEvent = (eventName, eventLabel, eventParams = {}) => {

  if (window.gtag) {
    try {
      window.gtag('event', eventName, {
        'event_category': 'Button',
        'event_label': eventLabel,
        ...eventParams
      });

    } catch (error) {

    }
  } else {
    console.warn('gtag not found. Make sure Google Analytics is properly set up.');
  }
};