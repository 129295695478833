// In App.js

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import './App.css';
import ImageEditor from "./ImageEditor";
import Instrucciones from "./Instrucciones";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FaFilePdf, FaImage, FaIdCard, FaMagic } from 'react-icons/fa';
import Result from './Result';
import * as faceapi from 'face-api.js';
import { PulseLoader } from 'react-spinners';
import SecurityFeatures from './SecurityFeatures';
import Header from './components/Header';
import { exportToPDF, exportAsCombinedImage, trackEvent } from './common';
import { pixelateFaces, convertToBlackAndWhite, addWatermark } from './imageFunctions';

function App() {
  const [editors, setEditors] = useState([{ id: 0 }]);
  const [completedEditors, setCompletedEditors] = useState({});
  const [editorData, setEditorData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [watermarkText, setWatermarkText] = useState('');
  const [croppedImages, setCroppedImages] = useState([]);
  const [processedImages, setProcessedImages] = useState([]);
  const [showInstructions, setShowInstructions] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const resultRef = useRef(null);

  const addEditor = () => {
    setEditors(prevEditors => [...prevEditors, { id: prevEditors.length }]);
    setShowInstructions(false);
  };

  const handleWatermarkTextChange = (event) => {
    setWatermarkText(event.target.value.slice(0, 128));
  };

  const handleOptionChange = (event) => {
    const option = event.target.value;
    setSelectedOptions(prevOptions =>
      prevOptions.includes(option)
        ? prevOptions.filter(item => item !== option)
        : [...prevOptions, option]
    );
  };

  const handleCropComplete = useCallback((id, isComplete) => {
    setCompletedEditors(prev => ({ ...prev, [id]: isComplete }));
  }, []);

  const updateEditorData = useCallback((id, data) => {
    setEditorData(prev => {
      const newData = [...prev];
      newData[id] = data;
      return newData;
    });

    if (data && data.croppedImage) {
      setCroppedImages(prev => {
        const newImages = [...prev];
        newImages[id] = URL.createObjectURL(data.croppedImage);
        return newImages;
      });
      setShowInstructions(false);
    }
  }, []);

  const handleImageUploaded = useCallback(() => {
    setShowInstructions(false);
    if (!modelsLoaded) {
      loadModels();
    }
  }, [modelsLoaded]);

  const allEditorsCompleted = editors.every(editor => completedEditors[editor.id]);

  const handleSubmit = async () => {
    setIsLoading(true);

    // Create an object with the selected options
    const selectedOptionsObj = {
      pixelar: selectedOptions.includes("pixelar"),
      blancoYNegro: selectedOptions.includes("blancoYNegro"),
      marcaAgua: selectedOptions.includes("marcaAgua"),
    };

    // Convert the object to a string for easier tracking
    const optionsString = Object.entries(selectedOptionsObj)
      .filter(([_, value]) => value)
      .map(([key, _]) => key)
      .join(',');

    const shouldPixelate = selectedOptions.includes("pixelar");
    const shouldConvertToBW = selectedOptions.includes("blancoYNegro");
    const shouldAddWatermark = selectedOptions.includes("marcaAgua");

    // Track the event with selected options
    trackEvent('generate_dni', 'Generar DNI', {
      selected_options: optionsString,
      watermark_used: selectedOptions.includes("marcaAgua") ? 'yes' : 'no',
      watermark_length: watermarkText.length,
    });

    try {
      const processedImages = await Promise.all(
        editorData.map(async (editor, index) => {
          if (editor && editor.croppedImage) {
            const img = await createImageBitmap(editor.croppedImage);
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            if (shouldPixelate) {
              await pixelateFaces(canvas);
            }

            if (shouldConvertToBW) {
              convertToBlackAndWhite(canvas);
            }

            if (shouldAddWatermark) {
              addWatermark(canvas, watermarkText);
            }

            return canvas.toDataURL('image/jpeg');
          }
          return null;
        })
      );

      setProcessedImages(processedImages.filter(img => img !== null));
      setShowResult(true);
    } catch (error) {
      console.error('Error processing images:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadModels = async () => {
    try {
      await faceapi.loadSsdMobilenetv1Model('/models');
      await faceapi.loadFaceLandmarkModel('/models');
      await faceapi.loadFaceDetectionModel('/models');
      setModelsLoaded(true);
    } catch (error) {
      console.error('Error loading models:', error);
    }
  };

  const removeEditor = (id) => {
    setEditors(prevEditors => prevEditors.filter(editor => editor.id !== id));
    setEditorData(prevData => {
      const newData = [...prevData];
      newData[id] = undefined;
      return newData;
    });
    setCompletedEditors(prev => {
      const newCompleted = { ...prev };
      delete newCompleted[id];
      return newCompleted;
    });
  };

  useEffect(() => {
    if (showResult && resultRef.current) {
      resultRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showResult]);

  const handleExportToPDF = () => {
    trackEvent('export_pdf', 'Guardar como PDF');
    exportToPDF(processedImages);
  };

  const handleExportAsImage = () => {
    trackEvent('export_image', 'Guardar como imagen');
    exportAsCombinedImage(processedImages);
  };

  const handleShare = () => {
    trackEvent('share', 'Compartir');
    // Implement your share functionality here
  };

  return (
    <div className="App">
      <Header />

      <div className='container' name="main">
        <div className='row justify-content-center'>
          <div className='p-0 col-12'>
            {showInstructions && <Instrucciones />}


            <div className="row">
              {editors.map((editor, index) => (
                <div key={editor.id} className={`col-12 ${editors.length > 1 ? 'col-lg-6' : ''} mb-4`}>
                  <ImageEditor
                    id={editor.id}
                    onCropComplete={handleCropComplete}
                    updateEditorData={updateEditorData}
                    onImageUploaded={handleImageUploaded}
                    isFirstEditor={index === 0}
                    onRemove={removeEditor}
                    totalEditors={editors.length}
                  />
                </div>
              ))}
            </div>

            {allEditorsCompleted && (
              <div className="d-flex justify-content-center mb-3">
                <button className="btn rounded ms-2 d-flex justify-content-center align-items-center" onClick={() => {
                  trackEvent('add_image', 'Añadir imagen');
                  addEditor();
                }} style={{ height: '35px', backgroundColor: 'white', color: '#14213d', border: '1px solid #14213d' }}>
                  <span className="me-1" style={{ fontSize: '18px', color: '#14213d' }}>+</span>
                  <span style={{ color: '#14213d', fontSize: '14px' }}>Añadir imagen</span>
                </button>
              </div>
            )}

            {allEditorsCompleted && (
              <>
                <div className="row justify-content-start">
                  <div className="col-md-8 col-12">
                    <div className="mt-3 px-4">
                      <div className="form-check form-switch mb-2 d-flex">
                        <input
                          className="form-check-input me-3 mt-1"
                          type="checkbox"
                          id="pixelar"
                          value="pixelar"
                          checked={selectedOptions.includes("pixelar")}
                          onChange={handleOptionChange}
                        />
                        <label className="form-check-label text-start" htmlFor="pixelar" id="pixelarLabel">
                          <div style={{ fontSize: '1.15rem' }}>Pixelar fotografía</div>
                          <small className="text-muted">Muestra la cara solo cuando sea necesario</small>
                        </label>
                      </div>
                      <div className="form-check form-switch mb-2 d-flex">
                        <input
                          className="form-check-input me-3 mt-1"
                          type="checkbox"
                          id="blancoYNegro"
                          value="blancoYNegro"
                          checked={selectedOptions.includes("blancoYNegro")}
                          onChange={handleOptionChange}
                        />
                        <label className="form-check-label text-start" htmlFor="blancoYNegro" id="blancoYNegroLabel">
                          <div style={{ fontSize: '1.15rem' }}>Convertir a blanco y negro</div>
                          <small className="text-muted">Reduce la visibilidad de detalles y minimiza su reuso indebido</small>
                        </label>
                      </div>
                      <div className="form-check form-switch mb-2 d-flex">
                        <input
                          className="form-check-input me-3 mt-1"
                          type="checkbox"
                          id="marcaAgua"
                          value="marcaAgua"
                          checked={selectedOptions.includes("marcaAgua")}
                          onChange={handleOptionChange}
                        />
                        <label className="form-check-label text-start" htmlFor="marcaAgua" id="marcaAguaLabel">
                          <div style={{ fontSize: '1.15rem' }}>Añadir marca de agua o texto</div>
                          <small className="text-muted">Indica el propósito del documento</small>
                        </label>
                      </div>
                      {selectedOptions.includes("marcaAgua") && (
                        <div className="mb-2">
                          <input
                            type="text"
                            className="form-control"
                            value={watermarkText}
                            onChange={handleWatermarkTextChange}
                            maxLength={128}
                            placeholder="Por ejemplo: Hotel Ritz"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row justify-content-center my-4'>
                  <button className="btn btn-dark btn-lg my-3 col-6" onClick={handleSubmit} disabled={isLoading} >
                    {isLoading ? (
                      <PulseLoader color="#fca311" size={10} />
                    ) : (
                      <>
                        <FaMagic className='mx-1' style={{ color: '#fca311' }} /> Generar DNI
                      </>
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {showInstructions && <SecurityFeatures />}

      <div className='row justify-content-center p-0 m-0'>
        <CSSTransition
          in={showResult}
          timeout={300}
          classNames="result"
          unmountOnExit
          nodeRef={resultRef}
        >
          <div ref={resultRef} className='p-0 mt-4'>
            <Result
              processedImages={processedImages}
              exportToPDF={handleExportToPDF}
              exportAsCombinedImage={handleExportAsImage}
              onShare={handleShare}
            />
          </div>
        </CSSTransition>
      </div>
    </div>
  );
}

export default App;
