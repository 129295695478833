import React from 'react';
import './SecurityFeatures.css';

const SecurityFeatures = () => {
  return (
    <div className="security-features mb-0" >
      <div className="security-content" style={{ fontSize: '1.25rem' }}>
        <ul>
          <li className="text-start"><strong>¡Protege tu DNI!</strong> Oculta información sensible para <em>prevenir su uso con fines ilícitos</em>.</li>
          <li className="text-start"><strong>100% SEGURO Y CONFIDENCIAL.</strong> <em>Ningún dato ni imagen sale de tu dispositivo</em> - todo se procesa localmente.</li>
          <li className="text-start"><strong>Y lo mejor de todo:</strong> <em>¡Totalmente GRATIS!</em> 🎉</li>
          <li className="text-start mt-4">
            Puedes ayudarme a mantener este sitio con una donación a través de Buy Me a Coffee
          </li>
        </ul>
        <a href="https://www.buymeacoffee.com/exoddus" target="_blank" rel="noopener noreferrer" className="mt-5 d-inline-block">
          <img
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
            alt="Buy Me A Coffee"
            style={{ height: '45px', width: '162.75px' }}
          />
        </a>
      </div>
    </div>
  );
};

export default SecurityFeatures;
